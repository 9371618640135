/////////////////// Filter Header ///////////////

.filter__control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.filter__input {
  width: 3.8rem !important;
  color: #fff !important;
  font-size: 12px !important;
  text-transform: uppercase;
  outline: none !important;
}
.filter__indicator-separator {
  display: none;
}
.filter__option {
  padding: 0.1rem 0.4rem !important;
  width: 100% !important;
  outline: none !important;

  &--is-focused {
    background-color: transparent !important;
  }
  &:hover {
    background-color: rgba($black, 0.2) !important;
  }
}
.filter__indicator,
.filter__dropdown-indicator {
  padding: 0 !important;
  margin: 0 !important;
  width: 10px;
  height: 5px;
  position: static;
  background: url('/assets/img/angle-down-white.svg') no-repeat center / contain;
  svg {
    display: none;
    width: 15px;
  }
}
.filter__placeholder {
  color: #fff !important;
  font-size: 12px !important;
  text-transform: uppercase;
  width: 5rem !important;
  outline: none !important;
}
.filter__value-container {
  padding: 0 !important;
  margin: 0 !important;
}
.filter__single-value {
  color: #fff !important;
  font-size: 12px !important;
}
.filter__menu {
  background: transparent !important;
  font-size: 12px !important;
  padding: 0 !important;
  width: 4.6rem !important;
  &-list {
    width: 4.6rem !important;
  }
}
/////////////////// Filter Phone Number ///////////////

.phonenumberfilter__control {
  background-color: transparent !important;
  padding: 0 0.4rem !important;
  outline: none !important;
  box-shadow: none !important;

  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  &--is-focused {
    padding: 0 0.4rem !important;
  }
}
.phonenumberfilter__control--is-focused {
  outline: none !important;
}
.phonenumberfilter__input {
  color: #fff !important;
  font-size: 16px !important;
  text-transform: uppercase;
  outline: none !important;
}
.phonenumberfilter__indicator-separator {
  display: none;
}
.phonenumberfilter__indicator,
.phonenumberfilter__dropdown-indicator {
  padding: 0 !important;
  margin: 0 !important;
  width: 10px;
  height: 10px;
  background: url('/assets/img/angle-down-white.svg') no-repeat center / contain;
  svg {
    display: none;
    width: 15px;
  }
}
.phonenumberfilter__placeholder {
  color: #fff !important;
  font-size: 16px !important;
  text-transform: uppercase;
  outline: none !important;
}
.phonenumberfilter__value-container {
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
}
.phonenumberfilter__single-value {
  color: #fff !important;
  font-size: 16px !important;
  width: 200px !important;
}
.phonenumberfilter__menu {
  font-size: 12px !important;
  padding: 0 0.4rem !important;
  width: 200px !important;
  margin-bottom: 2px;
}

.phonenumberfilter__menu-list {
  width: 200px !important;
  position: relative !important;
  z-index: 99 !important;
  max-height: 300px !important;
  background-color: rgba($black, 0.2) !important;
}
.phonenumberfilter__menu {
  position: absolute !important;
  z-index: 99 !important;
  background-color: rgba($black, 0.2) !important;
  top: 38px !important;
  left: -5px !important;
}
.css-b8ldur-Input {
  padding: 0 !important;
  border-radius: none !important;
}
.phonenumberfilter__option {
  width: 100% !important;
  padding: 0.2rem !important;
  &--is-focused {
    background-color: transparent !important;
  }
  &:hover {
    background-color: rgba($black, 0.4) !important;
  }
}

/////////////////// Filter Footer ///////////////
.filterfooter__control {
  background-color: transparent !important;
  border: none !important;
  display: flex !important;
  box-shadow: none !important;
  @media (min-width: $desktop) {
    display: none !important;
  }
}
.filterfooter__input {
  width: 3.5rem !important;
  outline: none !important;

  color: #fff !important;
  font-size: 12px !important;
  text-transform: uppercase;
}
.filterfooter__indicator-separator {
  display: none;
}
.filterfooter__option {
  padding: 0.1rem 0.4rem !important;
  outline: none !important;

  width: 100% !important;
  &--is-focused {
    background-color: transparent !important;
  }
  &:hover {
    background-color: rgba($black, 0.2) !important;
  }
}
.filterfooter__indicator,
.filterfooter__dropdown-indicator {
  outline: none !important;

  padding: 0 !important;
  margin: 0 !important;
  width: 10px;
  height: 5px;
  position: static;
  background: url('/assets/img/angle-down-white.svg') no-repeat center / contain;
  svg {
    display: none;
    width: 15px;
  }
}
.filterfooter__placeholder {
  outline: none !important;

  width: 3.5rem !important;
  color: #fff !important;
  font-size: 12px !important;
  text-transform: uppercase;
}
.filterfooter__value-container {
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
}
.filterfooter__single-value {
  color: #fff !important;
  font-size: 12px !important;
  outline: none !important;
}
.filterfooter__menu {
  &:first-child {
    top: -138px !important;
  }
  position: absolute !important;
  z-index: 99 !important;
  background-color: rgba($black, 0.8) !important;
  outline: none !important;
}
.filterfooter__menu-list {
  outline: none !important;

  position: relative !important;
  z-index: 99 !important;
  background-color: rgba($black, 0.8) !important;
}

/////////////////// Filter Date ///////////////
.completionDate__control {
  background-color: transparent !important;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  box-shadow: none !important;
}
.completionDate__indicator-separator {
  display: none !important;
}
.completionDate__single-value {
  color: #fff !important;
  padding-left: 0.3rem !important;
  font-size: 13px !important;
}
.completionDate__menu {
  top: -285px !important;

  position: absolute !important;
  z-index: 99 !important;
  background-color: #27282a !important;
}
.completionDate__menu-list {
  position: relative !important;
  z-index: 99 !important;
  background-color: #27282a !important;
}
.completionDate__option {
  font-size: 13px !important;
  &--is-focused {
    background-color: #777777 !important;
  }
}
.completionDate__placeholder {
  font-size: 13px !important;
  color: #fff !important;
}
/////////////////// Filter Date Mobile ///////////////
.css-2b097c-container {
  z-index: 99999999;
}
.completionDateMobile__control {
  background-color: transparent !important;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  padding: 0.1rem 0 !important;
  z-index: 999999 !important;
  box-shadow: none !important;
}
.completionDateMobile__indicator-separator {
  display: none !important;
}
.completionDateMobile__single-value {
  color: #fff !important;
  padding-left: 0.3rem !important;
  font-size: 13px !important;
}
.completionDateMobile__menu {
  background-color: #27282a !important;
  position: relative !important;
  z-index: 9999999999 !important;
}
.completionDateMobile__menu-list {
  background-color: #27282a !important;
  position: relative !important;
  z-index: 9999999999 !important;
}
.completionDateMobile__option {
  font-size: 13px !important;
  &--is-focused {
    background-color: #777777 !important;
  }
}
.completionDateMobile__placeholder {
  font-size: 13px !important;
  color: #fff !important;
}
/////////////////// Filter MinMAx ///////////////

.minmaxPrice__control {
  background-color: transparent !important;
  padding: 0.1rem 0 !important;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  box-shadow: none !important;
}
.minmaxPrice__indicator-separator {
  display: none !important;
}
.minmaxPrice__single-value {
  color: #fff !important;
  padding-left: 0.3rem !important;
  font-size: 13px !important;
}
.minmaxPrice__menu {
  background-color: #27282a !important;
}
.minmaxPrice__menu-list {
  background-color: #27282a !important;
}
.minmaxPrice__option {
  font-size: 13px !important;
  &--is-focused {
    background-color: #777777 !important;
  }
}
.minmaxPrice__placeholder {
  font-size: 13px !important;
  color: #fff !important;
}
/////////////////// Filter Money Mobile ///////////////

.filterMoneyMobile__control {
  padding: 0.1rem 0 !important;
  background-color: transparent !important;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  box-shadow: none !important;
}
.filterMoneyMobile__value-container {
  padding: 2px !important;
}
.filterMoneyMobile__indicator-separator {
  display: none !important;
}
.filterMoneyMobile__indicator {
  padding: 2px !important;
}
.filterMoneyMobile__single-value {
  color: #fff !important;
  padding-left: 0.3rem !important;
  font-size: 13px !important;
}
.filterMoneyMobile__menu {
  background-color: #27282a !important;
}
.filterMoneyMobile__menu-list {
  background-color: #27282a !important;
}
.filterMoneyMobile__option {
  font-size: 13px !important;
  &--is-focused {
    background-color: #777777 !important;
  }
}
.filterMoneyMobile__placeholder {
  font-size: 13px !important;
  color: #fff !important;
}
/////////@extend
.css-6q0nyr-Svg {
  width: 13px !important;
}
