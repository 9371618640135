//SEARCH

#search {
  background-color: #161718;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 98;
  padding: 80px 15px 95px 15px;
  overflow-y: auto;
  display: none;
  opacity: 0;
  flex-direction: column;
  .development-mode & {
    display: block;
    opacity: 1;
    @media (min-width: $desktop) {
      display: flex;
    }
  }
  @media (min-width: $desktop) {
    flex-direction: column;
    justify-content: center;
    padding-top: 40px;
    overflow-y: auto;
  }
  @media (max-height: 640px) and (min-width: $desktop) {
    justify-content: flex-start;
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .form-group {
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(100px);
    .development-mode & {
      opacity: 1;
      transform: translateY(0);
    }
    h4 {
      margin-bottom: 10px;
      @media (min-width: $desktop) {
        font-size: 16px;
      }
    }
  }
  form {
    @media (min-width: $desktop) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

#filter-lifestyle {
  display: flex;
  flex-wrap: wrap;

  .card-checkbox {
    flex: 1;
    margin-right: 15px;
    @media (min-width: $desktop) {
      flex: none;
      width: 100px;
      margin-right: 30px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

#filter-property-type {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 70px;
    z-index: 2;
    background-image: linear-gradient(
      90deg,
      rgba(22, 23, 24, 0) 0%,
      #161718 100%
    );
    pointer-events: none;
    display: none;
    @media (min-width: $desktop) {
      display: none;
    }
  }
  .box-checkbox {
    flex: 1;
    margin-bottom: 10px;
    @media (min-width: $desktop) {
      margin-right: 10px;
    }
    &:nth-child(odd) {
      margin-right: 10px;
    }
  }
}

#filter-property-type-scroll-wrapper {
  display: flex;
  overflow-y: auto;
  padding-bottom: 14px;
  @media (min-width: $desktop) {
    padding-bottom: 0;
  }
  &:after {
    content: "";
    display: block;
    width: 70px;
    height: 1px;
    flex-shrink: 0;
    @media (min-width: $desktop) {
      display: none;
    }
  }
}

#filter-bedrooms {
  display: flex;
  justify-content: space-between;
  .box-checkbox {
    margin-right: 10px;
    display: block;
    width: 100%;
    &:first-child {
      .custom-checkbox {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    .custom-checkbox {
      padding-left: 10px;
      padding-right: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

#filter-price {
  @media (min-width: $desktop) {
    width: 380px;
  }
}

#search-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 20px;
  background-color: #444;
  transform: translateY(20px);
  .development-mode & {
    opacity: 1;
    transform: translateY(0);
  }
  @media (min-width: $desktop) {
    position: static;
    width: 200px;
    border-radius: 2px;
    padding: 0;
    background: none;
  }
}
