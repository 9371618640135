//FIXED BUTTON WRAPPER

.fixed-button-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 40;
  padding: 20px;
}
