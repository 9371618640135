//REGISTER FORM
#register-form {
  background: url("/assets/img/texture.jpg");
  padding: 90px 0;
  h3 {
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 1px;
    @media (min-width: $desktop) {
      letter-spacing: 2px;
    }
  }
  form {
    margin-bottom: 45px;
  }
}

.get-in-touch{
  padding-top: 70px;
}

.awards-container {
  img {
    height: 80px;
  }
}

#get-in-touch {
  text-align: center;
  h6 {
    display: inline-block;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid rgba(#fff, 0.1);
    margin-bottom: 30px;
  }
}

#get-in-touch-items {
  display: flex;
  justify-content: space-between;
}
.get-in-touch-item {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
  &:hover,
  &:focus {
    color: #fff;
    outline: none;
    text-decoration: none;
  }
  .icon {
    font-size: 24px;
    width: 1.35em;
    margin-right: 10px;
  }
}
