//HERO CONTENT

.hero-content {
  text-align: center;
  margin-bottom: 60px;
  @media (min-width: $desktop) {
    margin-bottom: 110px;
  }
  p {
    color: rgba(#fff, 0.9);
    @media (min-width: $desktop) {
      margin-bottom: 24px;
    }
  }
}

.hero-content-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: 0;
  }
}
