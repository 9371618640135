//FOOTER

#footer {
  background-color: #222;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: $desktop) {
    padding: 0;
    display: block;
  }
  .container-fluid {
    @media (min-width: $desktop) {
      height: 84px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.footer-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: $desktop) {
    flex-direction: row;
    width: auto;
  }
  a {
    color: rgba(#fff, 0.7);
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: $desktop) {
      margin-bottom: 0;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.footer-logo {
  display: inline-block;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  color: #fff;
  text-align: center;
  flex-shrink: 0;
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    outline: none;
  }
  @media (min-width: $desktop) {
    margin: 0;
  }
  img {
    height: 25px;
  }
}
.footer-select {
  display: block;
  @media (min-width: $desktop) {
    display: none;
  }
}
#section-footer {
  .fp-tableCell {
    display: none;
  }
}

.designed-by{
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 0 !important;
  @media (max-width: $mobile) {
    display: none;
  }
  @media (min-width: $desktop) {
    display: inline;
  }
}
.designed-by-mobile{
  @media (min-width: $desktop) {
    display: none;
  }
  @media (max-width: $mobile) {
    display: inline;
  }
}